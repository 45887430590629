import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Form, Button, Card } from "react-bootstrap";

import PsContext from "../../../context/PsContext";
import OrderSummary from "../Cart/OrderSummary";
import LoginForm from "../userAccount/loginForm";
import UpdateAddress from "../userAccount/updateAddress";
import PaymentOptions from "./paymentOptions";

import EMPTY_CART from "../../../assets/img/empty_cart.png";

import Cart from "../Cart";

const styles = {
  btnStyle: {
    position: "absolute",
    right: "25px",
  },
};

const NewCart = () => {
  const context = useContext(PsContext);

  const getCurrentStep = () => {
    return context.state.userLogged == "yes" ? 2 : 1;
  };

  const [currentStep, setCurrentStep] = useState(getCurrentStep());

  useEffect(() => {
    context.scrollTop();
  }, []);

  if (context.state.cartCount < 1) {
    return (
      <div className="container">
        <Row>
          <Col md={12}>
            <div className="text-center">
              <img src={EMPTY_CART} />
              <h5>Your cart is empty</h5>
              <Link to="/" className="btn btn-dark mt-4">
                {" "}
                Shop Now
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <>
      <div className="tf-page-title mb-5">
        <div className="container-full">
          <div className="heading text-center">Check Out</div>
          <p className="text-center text-2 text_black-2 mt_5">
            Shop through our latest selection of Fashion
          </p>
        </div>
      </div>

      <div className="container mt-50">
        <Row>
          <Col md={8} className="pb-5">
            <Card className="cart-card">
              <Card.Body>
                <Card.Header
                  className={
                    currentStep == 1
                      ? "text-uppercase card-header-active"
                      : "text-uppercase"
                  }
                >
                  <span>1.</span> LOGIN OR SIGNUP
                  {context.state.userLogged == "yes" && (
                    <div style={styles.btnStyle}>
                      <Button
                        type="button"
                        variant="light"
                        size="sm"
                        onClick={() => context.userLogout()}
                      >
                        LOGOUT
                      </Button>
                    </div>
                  )}
                </Card.Header>
              </Card.Body>
            </Card>
            {(currentStep == 1 || context.state.userLogged != "yes") && (
              <Card className="cart-card">
                <Card.Body>
                  <Card.Text>
                    <Row>
                      <Col md={{ span: 6 }}>
                        <LoginForm afterFinish={() => setCurrentStep(2)} />
                      </Col>
                    </Row>
                  </Card.Text>
                </Card.Body>
              </Card>
            )}

            <Card className="cart-card mt-3">
              <Card.Body>
                <Card.Header
                  className={
                    currentStep == 2
                      ? "text-uppercase card-header-active"
                      : "text-uppercase"
                  }
                >
                  <span>2.</span> Delivery Address
                  {currentStep > 2 && context.state.userLogged == "yes" && (
                    <div style={styles.btnStyle}>
                      <Button
                        type="button"
                        variant="light"
                        size="sm"
                        onClick={() => setCurrentStep(2)}
                      >
                        EDIT
                      </Button>
                    </div>
                  )}
                </Card.Header>
              </Card.Body>
            </Card>

            {currentStep == 2 && context.state.userLogged == "yes" && (
              <Card className="cart-card">
                <Card.Body>
                  <Card.Text>
                    <UpdateAddress afterFinish={() => setCurrentStep(3)} />
                  </Card.Text>
                </Card.Body>
              </Card>
            )}

            <Card className="cart-card mt-3">
              <Card.Body>
                <Card.Header
                  className={
                    currentStep == 3
                      ? "text-uppercase card-header-active"
                      : "text-uppercase"
                  }
                >
                  <span>3.</span> ORDER SUMMARY
                  {currentStep > 3 && context.state.userLogged == "yes" && (
                    <div style={styles.btnStyle}>
                      <Button
                        type="button"
                        variant="light"
                        size="sm"
                        onClick={() => setCurrentStep(3)}
                      >
                        EDIT
                      </Button>
                    </div>
                  )}
                </Card.Header>
              </Card.Body>
            </Card>

            {currentStep == 3 && context.state.userLogged == "yes" && (
              <Card className="cart-card">
                <Card.Body>
                  <Card.Text>
                    <Cart afterFinish={() => setCurrentStep(4)} />
                  </Card.Text>
                </Card.Body>
              </Card>
            )}

            <Card className="cart-card mt-3">
              <Card.Body>
                <Card.Header
                  className={
                    currentStep == 4
                      ? "text-uppercase card-header-active"
                      : "text-uppercase"
                  }
                >
                  <span>4.</span> Payment Options
                </Card.Header>
              </Card.Body>
            </Card>

            {currentStep == 4 && context.state.userLogged == "yes" && (
              <Card className="cart-card">
                <Card.Body>
                  <Card.Text>
                    <PaymentOptions afterFinish={() => setCurrentStep(4)} />
                  </Card.Text>
                </Card.Body>
              </Card>
            )}
          </Col>
          <Col md={4}>
            {/*<Card>
					<Card.Body>
						<OrderSummary showFooter={true} />
					</Card.Body>
				</Card>*/}
          </Col>
        </Row>
      </div>
    </>
  );
};
export default NewCart;
