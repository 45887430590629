const COOKIE_KEY = "cb_ecom";

const Api = {
  // Url: "https://shibanifashion.com/site/api/",
  // ImageUrl: "https://shibanifashion.com/billing/services/",

  Url: "https://shibanifashion.com/site/api/",
  ImageUrl: "https://shibanifashion.com/billing/services/",
  //Url2: "https://shibanifashion.com/billing/api/",
  CloudImageUrl: "https://shibanifashion.com/files_list/",
};

export { COOKIE_KEY, Api };
