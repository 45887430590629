import React from "react";
import ReactDOM from "react-dom";
import "./assets/lineicons/LineIcons.min.css";
import "./assets/css/style.min.css";
import "./index.css";
import "./assets/css/bootstrap.css";
import "./assets/css/style.css";
import "./assets/css/custom-surya.css";
import "./assets/fonts/font-icons.css";

import App from "./App";

import "./serviceWorker";

ReactDOM.render(<App />, document.getElementById("root"));
