import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Row, Col } from "react-bootstrap";

import { Api } from "../../../utils/Variables";
import { ServiceUrl } from "../../../utils/ServiceUrl";

const CategoriesMenu = () => {
  const [categories, setCategories] = useState([]);

  /**
   * to load all the categories and sub categories from service
   */
  const loadCategories = () => {
    axios.get(`${Api.Url}${ServiceUrl.MENU.MEGA_MENU}`).then((res) => {
      console.log(res.data);
      if (res["data"].status === "1") {
        setCategories(res["data"].data);
      }
    });
  };

  useEffect(() => {
    loadCategories();
  }, []);

  /**
   * to load all the main categories at top level
   * call the singleCategory to load all the child categories
   **/
  const mainCategories = () => {
    return categories.map((item, i) => {
      return (
        <Col key={i}>
          <div className="list-group list-group-flush list-group-no-border list-group-sm">
            <Link
              to={`/category/${item.slug}`}
              className="list-group-item list-group-item-action"
            >
              <strong>{item.categoryname.toUpperCase()}</strong>
            </Link>

            {item.subcategory && item.subcategory.length > 0 ? (
              <div className="subcategories">
                {singleCategory(item.subcategory)}
              </div>
            ) : (
              <div className="no-subcategories">No - Sub</div>
            )}
          </div>
        </Col>
      );
    });
  };

  /**
   * single category and sub categories
   **/
  const singleCategory = (items) => {
    return items && items.length > 0 ? (
      items.map((item, i) => (
        <Link
          key={i}
          to={`/category/${item.slug}`}
          className="list-group-item list-group-item-action"
        >
          {item.categoryname}
        </Link>
      ))
    ) : (
      <div>No subcategories available</div>
    );
  };

  return (
    <div
      className="dropdown-menu"
      aria-labelledby="megaDropdown"
      style={{ width: "900px", backgroundColor: "#b0842b" }}
    >
      <Row>{mainCategories()}</Row>
    </div>
  );
};

export default CategoriesMenu;
