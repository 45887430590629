import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Row, Col } from "react-bootstrap";

import { Api } from "../../../utils/Variables";

const CategoriesMenu = (props) => {
  const [categories, setCategories] = useState([]);

  /**
   * to load all the categories and sub categories from service
   */
  const loadCategories = () => {
    axios.get(`${Api.Url}v1/site/categorylist/4`).then((res) => {
      if (res["data"].status == "1") {
        setCategories(res["data"].data);
      }
    });
  };

  useEffect(() => {
    loadCategories();
  }, []);

  /**
   * to load all the main categories at top level
   * call the singleCategory to load all the child categories
   **/
  const mainCategories = () => {
    var rv = [];
    if (categories && categories.length > 0) {
      categories.map((item, i) => {
        if (item.subcategory && item.subcategory.length > 0) {
          if (item.subcategory && item.subcategory.length > 0)
            rv.push(singleCategory(item.subcategory));
        }
      });
    }
    return rv;
  };

  /**
   * single category and sub categories
   **/
  const singleCategory = (items) => {
    var rv = [];
    items.map((item, i) => {
      rv.push(
        <li>
          <Link to={`/category/${item.slug}`} onClick={() => props.menuClick()}>
            {item.categoryname}
          </Link>
        </li>
      );
    });
    return rv;
  };

  return <>{mainCategories()}</>;
};

export default CategoriesMenu;
