import React, { useContext, useEffect } from "react";
import { Redirect, Link, NavLink } from "react-router-dom";
import $ from "jquery";
import CategoriesMenu from "./categoriesMenu";
import CategoryTypeMenu from "./categoryTypeMenu";

const TopMenu = () => {
  useEffect(() => {
    window.addEventListener("scroll", handleWindowScroll);
  });

  const handleWindowScroll = () => {
    var header = document.getElementById("sticky-header");

    if (header && header != null) {
      var sticky = header.offsetTop;

      if (window.pageYOffset > sticky) {
        header.classList.add("fixed-top");
      } else {
        header.classList.remove("fixed-top");
      }
    }
  };

  return (
    <header className="top_header_menu d-none d-sm-block" id="sticky-header">
      <div className="container-fluid">
        <nav className="nav nav-circle d-flex d-lg-none">
          <a
            href="#menuModal"
            data-toggle="modal"
            className="nav-link nav-icon"
          >
            <i className="material-icons">menu</i>
          </a>
          <div className="" style={{ position: "absolute", right: "25px" }}>
            <a className="btn nav-icon has-badge " href="account-wishlist.html">
              <i className="material-icons">shopping_cart</i>
              <span className="badge badge-pill badge-danger">4</span>
            </a>
          </div>
        </nav>

        <div className="header-bottom line">
          <div className="container-full px_15 lg-px_40">
            <div
              className="wrapper-header d-flex justify-content-center align-items-center"
              style={{ height: "54px" }}
            >
              <nav className="nav box-navigation text-center">
                <Link className="nav-item nav-link" to="/">
                  HOME
                </Link>

                <div className="nav-item dropdown dropdown-hover dropdown-mega">
                  <a
                    className="nav-link dropdown-toggle no-caret item-link"
                    href="javascript:;"
                    id="megaDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    data-display="static"
                  >
                    CATEGORIES
                  </a>{" "}
                  <CategoriesMenu />
                </div>

                <CategoryTypeMenu
                  type="collection"
                  limit="20"
                  title="COLLECTIONS"
                />

                <CategoryTypeMenu
                  type="occasion"
                  limit="20"
                  title="OCCASIONS"
                />

                <Link className="nav-item nav-link" to="/offers">
                  OFFERS <span className="badge badge-success">new</span>
                </Link>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default TopMenu;
