import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import $ from "jquery";
import { Carousel } from "react-bootstrap";

class CategoryWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="card card-2col text-white zoom-hover">
        {this.props.image ? (
          <img
            className="card-img"
            src={this.props.image}
            alt={this.props.title}
          />
        ) : null}

        <div className="card-img-overlay card-img-overlay-transparent flex-center flex-column">
          <h2 className="card-title">{this.props.title}</h2>

          {this.props.content ? (
            <p className="text-center d-none d-lg-block">
              {this.props.content}
            </p>
          ) : null}

          {this.props.url ? (
            <a
              href={this.props.url}
              className="btn btn-warning rounded-pill"
              data-addclass-on-xs="btn-sm"
            >
              Shop Now
            </a>
          ) : null}
        </div>
      </div>
    );
  }
}
export default CategoryWidget;
