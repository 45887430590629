import React, { Component, Suspense, lazy } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  Link,
  HashRouter,
  IndexRoute,
} from "react-router-dom";
import { LineLoader } from "../views/elements";
import SiteLayout from "../layouts/SiteLayout";

export default () => (
  <BrowserRouter basename="/">
    <Suspense
      fallback={
        <div>
          <LineLoader loading={true} />
        </div>
      }
    >
      <Switch>
        <Route path="" exact component={SiteLayout} />
      </Switch>
    </Suspense>
  </BrowserRouter>
);
