import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import $ from "jquery";
import { Row, Col, Card } from "react-bootstrap";
import { toast } from "react-hot-toast";

import PsContext from "../../../context/PsContext";
import { Api } from "../../../utils/Variables";
import { getCookieField } from "../../../utils";
import NO_PRODUCT_IMG from "../../../assets/img/empty.webp";
import FeaturedProduct from "../../elements/Products/Featured";
import ProductLoader from "../Home/productLoader";

const Offers = (props) => {
  const context = useContext(PsContext);
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]);

  const loadData = () => {
    setLoader(true);
    axios.get(Api.Url + "v1/site/offerlist").then((res) => {
      if (res["data"].status == "1") {
        setData(res["data"].data);
      }
      setLoader(false);
    });
  };

  const bodyToTop = () => {
    $("html, body").animate({ scrollTop: 0 }, "slow");
  };

  const addToCart = (id) => {
    var d = data;
    var index = d.findIndex(function (obj) {
      return obj.id == id;
    });
    var dat = d[index];
    dat["quantity"] = 1;
    context.addToCart(dat);
    toast.success("Successfully added to cart!.");
  };

  useEffect(() => {
    loadData();
    bodyToTop();
  }, []);

  const showItems = () => {
    let col = data.length > 3 ? 3 : 6;
    if (data.length == 3) col = 4;
    if (data.length == 2) col = 6;

    var rv = [];
    data.map((item, i) => {
      var amt_minus =
        (parseFloat(item.salesrate) * parseFloat(item.value)) / 100;

      rv.push(
        <Col md={col} key={item.id}>
          <Link to={`/${item.slug}/p/${item.itemid}`}>
            <Card className="mb-3 card-product">
              <img
                src={`${Api.ImageUrl}cimage/thumb/${item.id}`}
                className="card-img lazy_products"
                alt={item.itemname}
              />
              <Card.Body>
                <span className="badge badge-warning">
                  {item.offername} OFF
                </span>
                <div
                  className="price"
                  style={{ paddingTop: "10px", paddingBottom: "10px" }}
                >
                  {parseFloat(item.value) > 0 ? (
                    <span className="del">Rs. {item.salesrate}</span>
                  ) : (
                    ""
                  )}
                  <span>
                    Rs.{" "}
                    {(
                      parseFloat(item.salesrate) - parseFloat(amt_minus)
                    ).toFixed(2)}
                  </span>
                </div>
                <div className="text-dark" style={{ fontSize: "14px" }}>
                  <b>{item.itemname}</b>
                </div>
              </Card.Body>
            </Card>
          </Link>
        </Col>
      );
    });
    return rv;
  };

  return (
    <>
      <div className="tf-page-title mb-50">
        <div className="container-full">
          <div className="heading text-center">Best Offers</div>
          <p className="text-center text-2 text_black-2 mt_5">
            Enjoy the Best Offers
          </p>
        </div>
      </div>

      <div className="container-fluid mb-3">
        <Row className="gutters-3">
          {loader && (
            <Col md={12} style={{ paddingTop: "100px" }}>
              <center>
                <div className="ps_round_loader"></div>
              </center>
            </Col>
          )}
          {data.length > 0 ? (
            showItems()
          ) : (
            <Col md={12}>
              <center>
                <img
                  style={{ width: "100%", maxWidth: "300px" }}
                  src={NO_PRODUCT_IMG}
                  alt="No products"
                />
                <h4 className="mt-5" style={{ fontSize: "20px" }}>
                  Ready! for the Attractive Offers.
                </h4>
              </center>
            </Col>
          )}
        </Row>
      </div>
    </>
  );
};

export default Offers;
