import React, { useState, useEffect, useContext } from "react";
import { withRouter, Link } from "react-router-dom";
import $ from "jquery";
import { Row, Col, Card, Carousel, Modal, Alert } from "react-bootstrap";
import { toast } from "react-hot-toast";
import PsContext from "../../../context/PsContext";
import API from "../../../utils/API";
import { Api } from "../../../utils/Variables";
import SizeChart from "../../../assets/img/chart.PNG";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import PAY1 from "../../../assets/img/payments/1.png";
import PAY2 from "../../../assets/img/payments/2.png";
import PAY3 from "../../../assets/img/payments/3.png";
import PAY4 from "../../../assets/img/payments/4.png";
import PAY5 from "../../../assets/img/payments/6.png";
import { Nav, Tab } from "react-bootstrap";
const SingleProductView = (props) => {
  const context = useContext(PsContext);
  const { slug, id } = props.match.params;

  const [loader, setLoader] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [sizeChart, setSizeChart] = useState(false);
  const [product, setProduct] = useState([]);
  const [features, setFeatures] = useState([]);
  const [images, setImages] = useState([]);
  const [qty, setQty] = useState(1);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [activeTab, setActiveTab] = useState("");
  const [offer, setOffer] = useState([]);
  const [lImage, setLImage] = useState([]);

  useEffect(() => {
    loadProduct();
    loadProductFeatures();
    loadProductImages();
    bodyToTop();
  }, [id]);

  const bodyToTop = () => {
    $("html, body").animate({ scrollTop: 0 }, "slow");
  };

  const loadProduct = () => {
    API.get("v1/site/item/product/" + id).then((res) => {
      if (res["data"].status == "1") {
        //salesdiscount
        //salesrate
        var p = res["data"].data[0];
        p.offer_id = null;
        p.offer_group = null;
        p.offer_value = null;
        p.offer_type = null;
        p.offer_name = null;
        if (res["data"].offer.length > 0) {
          var off = res["data"].offer[0];
          var amt_minus =
            (parseFloat(p.salesrate) * parseFloat(off.value)) / 100;
          var srate = parseFloat(p.salesrate) - parseFloat(amt_minus);
          p.salesdiscount = parseFloat(p.salesrate).toFixed(2);
          p.salesrate = parseFloat(srate).toFixed(2);
          p.offer_id = off.id;
          p.offer_group = off.offer_group;
          p.offer_value = off.value;
          p.offer_name = off.name;
          p.offer_type = "percentage";
          setOffer(off);
        }
        setProduct(p);
      } else {
        setLoader(false);
      }
    });
  };

  useEffect(() => {
    if (features.length > 0) {
      setActiveTab(features[0].field); // Set first tab as active
    }
  }, [features]); // Runs only when `features` changes

  const loadProductFeatures = () => {
    setLoader(true);
    API.get("v1/site/item/features/" + id).then((res) => {
      if (res["data"].status == "1") {
        setFeatures(res["data"].data);
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  };

  const loadProductImages = () => {
    setLoader(true);
    API.get("v1/site/item/images/" + id).then((res) => {
      if (res["data"].status == "1") {
        let s = [];
        res["data"].data.map((item, i) => {
          s.push(Api.ImageUrl + item.image);
        });
        setImages(res["data"].data);
        setLImage(s);
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  };

  const addToCart = () => {
    var dat = product;
    dat["quantity"] = qty;
    context.addToCart(dat);
    toast.success("Successfully added to cart!.");
  };

  if (!loader) {
    return (
      <>
        <div className="tf-page-title mb-50">
          <div className="container-full">
            <div className="heading text-center">{product.itemname}</div>
            <p className="text-center text-2 text_black-2 mt_5">Our Products</p>
          </div>
        </div>

        <section className="flat-spacing-4 pt_0">
          <div className="tf-main-product">
            <div className="container">
              <Row>
                <Col md={6}>
                  <Card className="card-style1">
                    <Card.Body>
                      <div className="swiper-container" id="product-slider">
                        <div className="swiper-wrapper">
                          {images && images.length < 1 && (
                            <img
                              className="product_laze_placeholder"
                              alt={product.itemname}
                            />
                          )}

                          <Carousel>
                            {images &&
                              images.length > 0 &&
                              images.map((item, i) => {
                                return (
                                  <Carousel.Item>
                                    <img
                                      style={{
                                        cursor: "pointer",
                                        minHeight: "400px",
                                      }}
                                      src={
                                        item.image != ""
                                          ? Api.ImageUrl + item.image
                                          : ""
                                      }
                                      className="d-block swiper-slide product_laze_placeholder"
                                      alt={item.itemname}
                                      onClick={(e) => setIsOpen(true)}
                                    />
                                  </Carousel.Item>
                                );
                              })}
                          </Carousel>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>

                <Col md={6}>
                  <div className="tf-product-info-wrap position-relative">
                    <div className="tf-zoom-main"></div>
                    <div className="tf-product-info-list other-image-zoom">
                      <div className="tf-product-info-title">
                        <h5
                          className="fw-bolder text-capitalize"
                          style={{ fontSize: "18px" }}
                        >
                          {product.itemname}
                          {offer && offer.id && (
                            <span
                              className="badge badge-warning off-badge"
                              style={{ fontSize: "15px", marginBottom: "5px" }}
                            >
                              {offer.name} OFF
                            </span>
                          )}
                        </h5>
                      </div>
                      <div className="tf-product-info-price">
                        <div className="price-on-sale text_black fw-bolder">
                          {product.salesdiscount > 0 ? (
                            <span className="del">
                              ₹ {product.salesdiscount}
                            </span>
                          ) : (
                            ""
                          )}
                          <span>₹ {qty * product.salesrate}</span>
                        </div>
                      </div>
                      <div className="tf-product-info-liveview">
                        <div className="liveview-count">18</div>
                        <p className="fw-6 fs-13">
                          People are viewing this right now
                        </p>
                      </div>
                      <div className="tf-product-info-variant-picker">
                        <div className="variant-picker-item">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="variant-picker-label">
                              Size:{" "}
                              <span className="fw-6 variant-picker-label-value">
                                S
                              </span>
                              {product &&
                                product.showsizechart &&
                                product.showsizechart == "1" && (
                                  <a
                                    href="javascript:;"
                                    style={{ fontSize: "14px" }}
                                    onClick={() => setSizeChart(true)}
                                  >
                                    (Find your size)
                                  </a>
                                )}
                            </div>
                          </div>
                          <div className="variant-picker-values">
                            <input
                              type="radio"
                              name="size1"
                              id="values-s"
                              checked=""
                            />
                            <label
                              className="style-text size-btn"
                              for="values-s"
                              data-value="S"
                            >
                              <p>S</p>
                            </label>
                            <input type="radio" name="size1" id="values-m" />
                            <label
                              className="style-text size-btn"
                              for="values-m"
                              data-value="M"
                            >
                              <p>M</p>
                            </label>
                            <input type="radio" name="size1" id="values-l" />
                            <label
                              className="style-text size-btn"
                              for="values-l"
                              data-value="L"
                            >
                              <p>L</p>
                            </label>
                            <input type="radio" name="size1" id="values-xl" />
                            <label
                              className="style-text size-btn"
                              for="values-xl"
                              data-value="XL"
                            >
                              <p>XL</p>
                            </label>
                          </div>

                          {product.salesdiscount > 0 && (
                            <div className="tf-product-info-price">
                              <span className="del">
                                ₹ {product.salesdiscount}
                              </span>
                            </div>
                          )}

                          {product && parseFloat(product.stockinhand) > 0 && (
                            <>
                              <div className="tf-product-info-quantity mt-50">
                                <div className="quantity-title fw-6">
                                  Quantity
                                </div>
                                <div className="wg-quantity">
                                  <span
                                    className="btn-quantity btn-decrease"
                                    onClick={() => qty > 1 && setQty(qty - 1)}
                                  >
                                    -
                                  </span>
                                  <input
                                    type="text"
                                    className="quantity-product"
                                    name="number"
                                    value={qty}
                                    readOnly
                                  />
                                  <span
                                    className="btn-quantity btn-increase"
                                    onClick={() => setQty(qty + 1)}
                                  >
                                    +
                                  </span>
                                </div>
                              </div>

                              <div className="tf-product-info-buy-button mt-50">
                                <form>
                                  <a
                                    href="javascript:void(0);"
                                    className="tf-btn btn-fill justify-content-center fw-6 fs-16 flex-grow-1 animate-hover-btn btn-add-to-cart"
                                    onClick={() => addToCart(qty)}
                                  >
                                    <span>Add to cart -&nbsp;</span>
                                    <span className="tf-qty-price total-price">
                                      ₹ {qty * product.salesrate}
                                    </span>
                                  </a>
                                </form>
                              </div>
                            </>
                          )}

                          {product && parseFloat(product.stockinhand) < 1 && (
                            <div className="mt-15">
                              <div className="alert alert-danger" role="alert">
                                No Stock available
                              </div>
                            </div>
                          )}

                          {product && product.about && (
                            <div className="mt-15">
                              <hr />
                              <h5>Description</h5>
                              <hr />
                              <p>{product.about}</p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div class="tf-product-info-delivery-return">
                        <div class="row">
                          <div class="col-xl-6 col-12">
                            <div class="tf-product-delivery h-100">
                              <div class="icon">
                                <i class="icon-delivery-time"></i>
                              </div>
                              <p className="fs-13">
                                Estimate delivery times:{" "}
                                <span class="fw-7">12-26 days</span>{" "}
                                (International),{" "}
                                <span class="fw-7">3-6 days</span> (India).
                              </p>
                            </div>
                          </div>
                          <div class="col-xl-6 col-12">
                            <div class="tf-product-delivery h-100">
                              <div class="icon">
                                <i class="icon-return-order"></i>
                              </div>
                              <p className="fs-13">
                                Return within <span class="fw-7">30 days</span>{" "}
                                of purchase. Duties & taxes are non-refundable.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tf-product-info-trust-seal">
                        <div class="tf-product-trust-mess">
                          <i class="icon-safe"></i>
                          <p class="fw-6 fs-13">
                            Guarantee Safe <br /> Checkout
                          </p>
                        </div>
                        <div class="tf-payment">
                          <img src={PAY1} />
                          <img src={PAY2} />
                          <img src={PAY3} />
                          <img src={PAY4} />
                          <img src={PAY5} />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <Card className="card-style1 sm-mt-2">
                    <Card.Body>
                      <h5 className="product-title">
                        <b></b>
                      </h5>

                      <h3 className="price product-price">
                        {product.salesdiscount > 0 ? (
                          <span className="del">
                            Rs. {product.salesdiscount}
                          </span>
                        ) : (
                          ""
                        )}
                        <span>Rs. {qty * product.salesrate}</span>
                      </h3>
                      {product &&
                        product.showsizechart &&
                        product.showsizechart == "1" && (
                          <a
                            href="javascript:;"
                            style={{ fontSize: "14px" }}
                            onClick={() => setSizeChart(true)}
                          >
                            (See size chart)
                          </a>
                        )}
                    </Card.Body>
                  </Card>

                  <Card className="card-style1 sm-mt-2">
                    <Card.Body>
                      {features && features.length > 0 && (
                        <div>
                          <hr />
                          {features.map((item1, i) => (
                            <div className="form-group">
                              <label className="bold d-block">
                                <b>{item1.field}</b>
                              </label>
                              <span>{item1.value}</span>
                            </div>
                          ))}
                          <hr />
                        </div>
                      )}
                    </Card.Body>
                  </Card>

                  {product && parseFloat(product.stockinhand) > 0 && (
                    <div className="product-add-to-cart">
                      <div className="form-row">
                        <div className="form-group col-6 col-sm-4 col-md-5 col-lg-4">
                          <label className="d-block font-weight-bold">
                            Quantity
                          </label>
                          <div className="custom-spinner">
                            <button
                              className="btn btn-icon rounded-circle btn-faded-primary down"
                              type="button"
                              onClick={() => {
                                if (qty > 1) setQty(qty - 1);
                              }}
                            >
                              <i className="material-icons">remove</i>
                            </button>
                            <input
                              type="number"
                              className="form-control"
                              value={qty}
                              min="1"
                              max="999"
                            />
                            <button
                              className="btn btn-icon rounded-circle btn-faded-primary up"
                              type="button"
                              onClick={() => {
                                setQty(qty + 1);
                              }}
                            >
                              <i className="material-icons">add</i>
                            </button>
                          </div>
                        </div>
                        <div className="form-group col-6 col-sm-8 col-md-7 col-lg-8 d-flex align-items-end">
                          <button
                            className="btn rounded-pill btn-theme btn-block atc-demo"
                            type="button"
                            onClick={() => addToCart()}
                          >
                            Add to Cart
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                  {product && parseFloat(product.stockinhand) < 1 && (
                    <div className="mt-15">
                      <Alert variant="danger" size="sm">
                        No Stock available
                      </Alert>
                    </div>
                  )}

                  {product && product.about && (
                    <div className="mt-15">
                      <hr />
                      <h5>Description</h5>
                      <hr />
                      {product.about}
                    </div>
                  )} */}
                </Col>
              </Row>
            </div>
          </div>
        </section>

        <section className="flat-spacing-17 pt_0">
          <div className="container">
            <div className="row">
              <div className="col-12">
                {features && features.length > 0 && (
                  <div className="widget-tabs style-has-border">
                    {/* Tabs Navigation */}
                    <ul className="widget-menu-tab">
                      {features.map((item, index) => (
                        <li
                          key={index}
                          className={`item-title ${
                            activeTab === item.field ? "active" : ""
                          }`}
                          onClick={() => setActiveTab(item.field)}
                        >
                          <span className="inner">{item.field}</span>
                        </li>
                      ))}
                    </ul>

                    {/* Tabs Content */}
                    <div className="widget-content-tab">
                      {features.map((item, index) => (
                        <div
                          key={index}
                          className={`widget-content-inner ${
                            activeTab === item.field ? "active" : ""
                          }`}
                        >
                          <div className="p-3 border rounded">{item.value}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>

        <Modal show={sizeChart} size="lg" onHide={() => setSizeChart(false)}>
          <Modal.Header closeButton>Size Chart</Modal.Header>
          <Modal.Body>
            <img src={SizeChart} style={{ width: "100%" }} />
          </Modal.Body>
        </Modal>

        {isOpen && (
          <Lightbox
            mainSrc={lImage[photoIndex]}
            nextSrc={lImage[(photoIndex + 1) % lImage.length]}
            prevSrc={lImage[(photoIndex + lImage.length - 1) % lImage.length]}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + lImage.length - 1) % lImage.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % lImage.length)
            }
          />
        )}
      </>
    );
  } else {
    return (
      <Row>
        <Col md={12} style={{ paddingTop: "100px" }}>
          <center>
            <div className="ps_round_loader"></div>
          </center>
        </Col>
      </Row>
    );
  }
};

export default withRouter(SingleProductView);
