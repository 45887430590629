import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import "./ringloader.css";

class RingLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="ringloader-container">
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }
}
export default RingLoader;
