import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import $ from "jquery";
import { Carousel } from "react-bootstrap";
import API from "../../../utils/API";
import { Api } from "../../../utils/Variables";

import SliderItem from "./SliderItem";

class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataList: [],
    };
  }

  componentDidMount() {
    this.loadSliders();
  }

  loadSliders() {
    try {
      API.get("sliders/").then((res) => {
        if (res["data"].status == "1") {
          this.setState({ dataList: res["data"].data });
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <Carousel className="ps_carousel">
        {this.state.dataList.map((item, i) => {
          return (
            <Carousel.Item>
              <img className="d-block w-100" src={Api.ImageUrl + item.path} />
            </Carousel.Item>
          );
        })}
      </Carousel>
    );
  }
}
export default Slider;
