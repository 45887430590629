import React, { useState, useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";

import PsContext from "../../../context/PsContext";

const TopSearchBar = (props) => {
  const context = useContext(PsContext);
  const [redirect, setRedirect] = useState(false);
  const [keyVal, setKeyVal] = useState(null);

  const btnClick = (e) => {
    e.preventDefault();
    const val = document.getElementById("inp_top_search_keyword").value;
    setKeyVal(val);
    setRedirect(true);
  };

  useEffect(() => {
    checkUrl();
  }, []);

  useEffect(() => {
    if (redirect) {
      props.history.push(`/search/${keyVal}`);
      setRedirect(false);
    }
  }, [redirect]);

  const checkUrl = () => {
    const path = props.location.pathname;
    const pathArray = path.split("/");
    if (pathArray.length > 1 && pathArray[1] === "search") {
      document.getElementById("inp_top_search_keyword").value = pathArray[2];
    } else {
      document.getElementById("inp_top_search_keyword").value = "";
    }
  };

  return (
    <div className="tf-search-sticky">
      <form className="tf-mini-search-frm" onSubmit={btnClick}>
        <fieldset className="text">
          <input
            type="text"
            className=""
            placeholder="Search"
            id="inp_top_search_keyword"
            name="text"
            required
            aria-required="true"
          />
        </fieldset>
        <button type="submit" className="">
          <i className="icon-search"></i>
        </button>
      </form>
    </div>
  );
};

export default withRouter(TopSearchBar);
